import { Injectable } from '@angular/core';
import { CheckoutStatusResponse } from '../models/checkout-status-response';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PaymentRequest } from '../models/payment-request';
import { PaymentResponse } from '../models/payment-response';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  getCheckoutStatus(transactionId: string)
  {    
    return this.http.get<CheckoutStatusResponse>(environment.apiUrl + '/checkout/status?paymentId=' + transactionId);
  }

  processPayment(pr: PaymentRequest)
  {    
    return this.http.post<PaymentResponse>(environment.apiUrl + '/checkout/stripe/fx',pr);
  }

}