import { MaterialModule } from './../../modules/material/material.module';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, timer, takeWhile, switchMap, takeUntil } from 'rxjs';
import { CheckoutStatusResponse } from '../../models/checkout-status-response';
import { OrderService } from '../../services/order.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-success-page',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  templateUrl: './success-page.component.html',
  styleUrl: './success-page.component.scss'
})
export class SuccessPageComponent {
  
  supportEmail = 'support@takenote.cloud';
  transactionId = '';
  responseStatus = '';
  orderNum = '';
  pin = '';
  isPaymentReceived = false;
  isCheckoutExpired = false;
  isTimeOut = false;
  counter = 0;
  MAX_COUNTER = 30;  // 1 minutes (30 * 2000)
  private unsub = new Subject<void>();

  constructor(private http: HttpClient,private router: Router, private route: ActivatedRoute, private orderService: OrderService){}

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
          console.log(params);
          this.transactionId = params['transactionid'];
          console.log(this.transactionId);
        }
      );

      // timer(0, 2000).pipe(
      //   tap((x) => console.log(x)),
      //   takeUntil(this.unsub),
      //   switchMap(() => this.getData())
      // ).subscribe();

      timer(0, 2000).pipe(
        // tap((x) => console.log(x)),
        // map(n => 100 - (n*2)),
        takeWhile(() => this.counter < this.MAX_COUNTER + 1),
        // tap((n) => console.log(n)),
        switchMap(() => this.orderService.getCheckoutStatus(this.transactionId)),
        takeUntil(this.unsub)
      ).subscribe(
        {
          next: (response: CheckoutStatusResponse) => {
            console.log(response);
            this.counter = this.counter + 1;
            console.log("counter: " + this.counter);
            console.log('Checkout status: ' + response.status);
            if (response.status === "COMPLETED") {
              this.orderNum = response.orderNum;
              this.pin = response.pin;
              this.isPaymentReceived = true;
              this.isCheckoutExpired = false;
              this.unsub.next();
              this.unsub.complete();
            }
            else if(response.status === "EXPIRED"){
              this.isPaymentReceived = true;
              this.isCheckoutExpired = true;
              this.unsub.next();
              this.unsub.complete();
            }
            else if(this.counter > this.MAX_COUNTER)
            {
              this.isTimeOut = true;
              this.unsub.next();
              this.unsub.complete();
            }
          },
          error: (error: any) => {
            console.error(error);
            this.counter = this.counter + 1;
            // handle errors (note that any errors would stop the polling)
          }
        }
      );
  }


  navigateToHome()
  {
    this.router.navigateByUrl('home');
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
